<template>
    <div class="account">
        <ul>
            <li>
                <div class="name">Email</div>
                <div class="val">{{ uinfo.account }}</div>
            </li>
            <li>
                <div class="name">Current subscription package</div>
                <div class="val">
                    <div>{{ userPackage }} month package
                        <span v-if="userPackage > 0">
                            (Expires {{ roleEndTime }},
                            {{ endDate }} days
                            remaining)</span>
                    </div>
                    <span class="btn btn_border" @click="toRenewal">Renewal</span>
                </div>
            </li>
        </ul>
    </div>
</template>
<style lang="scss" scoped>
li {
    height: 0.8rem;
    background: #F5F5FA;
    border-radius: 0.1rem;
    margin-bottom: 0.2rem;
    padding: 0.2rem;
    color: #4F4F4F;

    .name {
        font-size: 0.14rem;
        line-height: 0.2rem;
        margin-bottom: 0.1rem;
    }

    .val {
        font-size: 0.24rem;
        color: #333;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
            font-size: 0.14rem;
            font-weight: normal;
            margin-left: 0.1rem;
        }

        .btn {
            width: 1.1rem;
            margin-left: 0;
        }
    }
}
</style>
<script>
import {
    defineComponent,
    computed,
    ref,
    onMounted
} from "vue";
import {
    useStore
} from "vuex";
import common from "../../utils/common";
import axios from "axios";
// import { useRouter } from "vue-router";

export default defineComponent({
    name: "account",
    props: {},
    components: {},
    setup(props, ctx) {
        // let router = useRouter();
        let store = useStore();

        //用户登录后的信息
        let uinfo = computed(() => {
            if (store.state.vpn.loginInfo) {
                return store.state.vpn.loginInfo.uinfo;
            } else {
                return false;
            }
        });
        //获取用户充值类型
        let userPackage = ref('-');
        let role_level = computed(() => {
            if (store.state.vpn.loginInfo) {
                return store.state.vpn.loginInfo.uinfo.role_level;
            } else {
                return false;
            }
        });
        switch (role_level.value) {
            case 0:
                userPackage = 0;
                break;
            case 1:
                userPackage = 1;
                break;
            case 2:
                userPackage = 4;
                break;
            case 3:
                userPackage = 12;
                break;
            default:
                userPackage = 0;
        }
        //用户到期时间
        let roleEndTime = common.formatDateTime(uinfo.role_end_time);
        console.log('--lb', "roleEndTime", roleEndTime)
        //剩余天数
        let endDate = Math.floor((new Date().getTime() - (uinfo.role_end_time * 1000)) / 1000 / 60 / 60 /
            24);
        //跳转续费
        const toRenewal = () => {
            store.commit('vpn/set_showTabIndex', 1);
        }
        onMounted(() => {
            const sendUrl = common.userApi.requestUrl + '/vpn_api/user_info';
            const sendHeader = common.buildVpnHeaders();
            console.log('--lb', sendHeader);
            const sendData = {
                headers: sendHeader,
            };
            common.showLoading('get vpn userinfo');
            axios.get(sendUrl, sendData).then((r) => {
                if (r.data.code == 0) {
                    store.commit("vpn/set_uid", r.data.uinfo.uid);
                    store.commit("vpn/set_loginInfo", r.data);
                }
            }).catch().finally(() => {
                common.hideLoading('get vpn userinfo');
            });
        });
        return {
            uinfo,
            userPackage,
            roleEndTime,
            endDate,
            toRenewal
        };
    },
});
</script>
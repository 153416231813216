<template>
    <div class="record"></div>
</template>
<style lang="scss" scoped></style>
<script>
    import {
        defineComponent
    } from "vue";
    // import { useStore } from "vuex";
    // import { useRouter } from "vue-router";

    export default defineComponent({
        name: "record",
        props: {},
        components: {},
        setup(props, ctx) {
            // let router = useRouter();
            //   let store = useStore();
            return {};
        },
    });
</script>
<template>
    <div class="template payment confirm">
        <div class="box">
            <div class="t">Confirm Order</div>
            <div class="c">
                <dl>
                    <dd><span class="lable">Combo</span><span class="val">{{ props.modelValue.product_name }}</span>
                    </dd>
                    <dd><span class="lable">Time</span><span class="val">{{ modelValue.valid_times / 86400 }}
                            day</span></dd>
                    <dd><span class="lable">Price</span><span class="val price">WU
                            {{ modelValue.price_currency_info.amount }}</span></dd>
                </dl>
            </div>
            <div class="close" @click="close"></div>
            <span class="btn btn_red" @click="pay">Payment</span>
        </div>
    </div>
</template>
<script setup>
import web3Tools from "../../utils/web3Tools";
import Web3 from "web3";
// import Moralis from "moralis";
import common from '@/utils/common';
import { computed } from "vue";
import { useStore } from "vuex";
let props = defineProps(['modelValue']);
let emits = defineEmits(['update:modelValue']);

let store = useStore();

let uid = computed(() => {
    return store.state.vpn.uid;
});

// console.log(Moralis)
// console.log(props.modelValue)

//VPN充值合约地址-测试网
let CONTRACT_ADDRESS_VPN = "0xd212880e3aE146Cd230A6Dee92a5348969805cd5";
//WAR链币合约地址-测试网
let CONTRACT_ADDRESS_WAT = "0x161736195cC76d85b8477CF0eB6DF44B7cC2a00D";
if (common.isProduction) {
    //VPN充值合约地址-主网
    CONTRACT_ADDRESS_VPN = "0x96cd7a63980dA439FDa1DD0eCf2cf30D526DCC6C";
    //WAR链币合约地址-主网
    CONTRACT_ADDRESS_WAT = "0x19ba726D46CbC2Bb5EBD26e459ef50C268545f44";
}

/* //套餐类型：包天、包月、包季、包年
const OrderType = {
    DAY: 1,
    MONTH: 2,
    SEASON: 3,
    YEAR: 4,
}

//套餐价格：包月、包季、包年
const OrderPrice = {
    DAY: "1",
    MONTH: "10",
    SEASON: "25",
    YEAR: "69",
}  */

//ERC777类型的链币转账abi
const erc777SendAbi = {
    "inputs": [
        {
            "internalType": "address",
            "name": "recipient",
            "type": "address"
        },
        {
            "internalType": "uint256",
            "name": "amount",
            "type": "uint256"
        },
        {
            "internalType": "bytes",
            "name": "data",
            "type": "bytes"
        }
    ],
    "name": "send",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}

const close = () => {
    emits('update:modelValue', null)
}

// 支付
const pay = async () => {


    common.showLoading('pay vpn');

    let orderPrice = props.modelValue.price_currency_info.amount;
    let orderType = props.modelValue.product_id;
    console.log('--lb', orderType, orderPrice)

    try {
        const web3Provider = await Moralis.enableWeb3();
        const web3 = new Web3(web3Provider);
        // const web3 = new Web3(window.ethereum);
        console.log('--lb', orderType, uid.value)
        const actionData = web3.eth.abi.encodeParameters(['uint8', 'uint64'], [orderType, uid.value]);
        console.log('--lb', actionData)
        const options = {
            contractAddress: CONTRACT_ADDRESS_WAT,
            functionName: 'send',
            abi: [erc777SendAbi],
            params: {
                recipient: CONTRACT_ADDRESS_VPN,
                amount: Moralis.Units.Token(orderPrice, 18),
                data: actionData
            }
        };
        const transaction = await Moralis.executeFunction(options);
        const res = await transaction.wait(3);

        console.log("res", res);

        common.hideLoading('pay vpn');
        toastr['success']('success!');
        close();
    } catch (error) {
        common.hideLoading('pay vpn');
        console.error("error", error);
        toastr['error']('pay error!' + error);
    }

}
</script>
<style lang="scss" scoped>
.confirm {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;

    .box {
        position: relative;
        width: 6.6rem;
        height: 4.08rem;
        background-color: #fff;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
        border-radius: 0.1rem;
        box-sizing: border-box;

        .t {
            height: 0.64rem;
            line-height: 0.64rem;
            font-size: 0.18rem;
            color: #333;
            font-weight: bold;
            text-align: left;
            padding: 0 0.3rem;
            border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        }

        .c {
            font-size: 0.16rem;
            padding: 0.3rem;
            text-align: left;

            dd,
            dl {
                margin: 0;
            }

            dd {
                height: 0.46rem;
                line-height: 0.46rem;
                border-bottom: 1px solid rgba(0, 0, 0, 0.15);

                .lable {
                    display: inline-block;
                    width: 0.75rem;
                }

                .val {
                    font-size: 0.16rem;
                    font-weight: bold;

                    &.price {
                        color: #EF4F55;
                    }
                }

            }
        }

        .btn_red {
            width: 1.8rem;
            position: absolute;
            right: 0.3rem;
            bottom: 0.3rem;
        }
    }


    .close {
        position: absolute;
        top: 0.2rem;
        right: 0.2rem;
        width: 0.4rem;
        height: 0.4rem;
        cursor: pointer;
        background: url("https://cdn.blockwar.io/web_assets/img/ico_close.png") no-repeat center center / 100% auto;

        &:hover {
            background-image: url("https://cdn.blockwar.io/web_assets/img/ico_close_hover.png");
        }
    }
}
</style>
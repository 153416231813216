<template>
    <div class="subscription">
        <h1>fast access</h1>
        <div class="title">Secure access, smooth traffic, and encrypt all your data.</div>
        <ul class="list">
            <li v-for="(item, inx) in goodsList" :key="inx">
                <div class="name">{{ item.product_name }}</div>
                <div class="discount"><span>{{ item.discount * 100 }}% OFF</span></div>
                <!-- <div>{{item.price_currency_info}}</div> -->
                <div>
                    <p class="price"><img src="https://cdn.blockwar.io/web_assets/img/ico_w.png" alt="">{{ item.per_month_price.amount }}</p>
                    <div class="pre">pre month</div>
                </div>
                <div class="btn_box">
                    <span class="btn btn_border" @click="currentItem = item">select</span>
                </div>
            </li>
        </ul>
        <p class="tips">This payment does not support automatic recurring debits, so your service will not be
            renewed.Payment. Need
            to pay for fuel.</p>
        <Payment v-if="currentItem" :modelValue="currentItem" @update:modelValue="newValue => currentItem = newValue">
        </Payment>
    </div>
</template>
<style lang="scss" scoped src="./subs.scss">

</style>
<script>
/* 
Hans, [2022-08-02 16:22]
陈永强 @liu_robin

vpn充值的合约部署好了，测试网合约地址：0x65D38B7855bD0bF98F0b4B12fba55046B72a29c2

强哥，合约的接口定义文件：IVpnRecharge
刘斌，测试例子：svn://cdrms.cn/guess_game/blockchain/BlockWar/unity_web_player/src/test/VpnTest.js

Hans, [2022-08-02 16:23]
vpn链上充值，实际上就是一个带参数的链币转账

Hans, [2022-08-02 16:25]
例子我不是用纯web3写的，用了Moralis接口，是因为老潘说马上要上一个开宝箱的功能，这个功能在网页里面、游戏里面都有，宝箱的数据存储在Moralis云上面，所以网页里面迟早要用Moralis接口的

Hans, [2022-08-02 16:25]
关于Moralis接口的文档：https://docs.moralis.io/introduction/readme

Hans, [2022-08-03 10:33]
陈永强 @liu_robin

vpn充值的新合约地址：CONTRACT_ADDRESS_VPN

增加了按天购买，修改了套餐价格
Hans, [2022-08-03 11:05]
 我擦，地址发错了：0x003E31931B1C43624152D0C33Be19623a4102f5c

@liu_robin 陈永强

强哥，订单id加上了，新的合约地址：0xEd21Cb25dad8d20747c8602Ed7d3E9F9b2467A83

刘斌，测试代码VpnTest.js里面的合约地址一直是最新的哈

陈永强 @liu_robin 
vpn充值的合约在主网部署好了：
vpn充值合约：0x96cd7a63980dA439FDa1DD0eCf2cf30D526DCC6C
wat链币合约：0x19ba726D46CbC2Bb5EBD26e459ef50C268545f44
*/
import axios from "axios";
import {
    defineComponent,
    computed,
    watch,
    ref, onMounted
} from "vue";
import common from "../../utils/common";
import {
    useStore
} from "vuex";
// import { useRouter } from "vue-router";
import web3Tools from "../../utils/web3Tools";
import Web3 from "web3";
// import Moralis from "moralis";
import Payment from "./Payment.vue";

export default defineComponent({
    name: "subscription",
    props: {},
    components: { Payment },
    setup(props, ctx) {
        // let router = useRouter();
        let store = useStore();
        let uid = computed(() => {
            return store.state.vpn.uid;
        });
        let utoken = computed(() => {
            return store.state.vpn.loginInfo.token;
        });

        //获取充值列表
        let goodsList = ref([]);
        const getGoodsList = () => {
            const sendUrl = common.userApi.requestUrl + '/vpn_api/pay/recharge_products';
            const sendHeader = common.buildVpnHeaders(uid.value, utoken.value);
            console.log('--lb', sendHeader);
            const sendData = {
                headers: sendHeader,
            };
            common.showLoading('recharge_products');
            console.log('--lb', 'uid.value', uid.value)
            if (uid.value) {
                axios.get(sendUrl, sendData).then((r) => {
                    if (r.data.code == 0) {
                        goodsList.value = r.data.infos.sort(function (a, b) {
                            return a.per_month_price.amount - b.per_month_price
                                .amount;
                        });
                        console.log('--lb', goodsList.value)
                    } else {
                        toastr['error'](r.data.msg);
                    }
                }).catch((e) => {
                    toastr['error'](e);
                }).finally((() => {
                    common.hideLoading('recharge_products');
                }));
            }
        }
        watch(uid, (newVal, oldVal) => {
            if (uid.value) {
                getGoodsList();
            }
        });
        getGoodsList();

        //currentItem被赋值将显示确认充值框
        let currentItem = ref('');
        console.log('--lb', window.web3.currentProvider.chainId)


        onMounted(() => {
            web3Tools.connectWeb3(web3Tools.getWalletAccount().then((r) => {
                console.log(r);
                store.commit("vpn/set_walletAddress", r);
            }));
        });
        return {
            goodsList,
            currentItem,
        };
    },
});
</script>